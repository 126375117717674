import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/templates/Page/index.js";
import { SocialLink } from '../components/Footer';
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <section className='take-space'>
      <h2 style={{
        "textAlign": "center",
        "marginTop": "100px"
      }}>For all business inquiries please email me:</h2>
      <h3 style={{
        "textAlign": "center"
      }}>nobrokethoughts90@gmail.com</h3>
      <section style={{
        "display": "flex",
        "justifyContent": "center",
        "textAlign": "center"
      }}>
	<p>
		<a href={SocialLink.soundcloud}>
			<img src="https://img.icons8.com/color/48/000000/soundcloud.png"></img>
		</a>
	</p>
	<p>
		<a href={SocialLink.youtube}>
			<img src="https://img.icons8.com/color/48/000000/youtube-play.png"></img>
		</a>
	</p>
	<p>
		<a href={SocialLink.instagram}>
			<img src="https://img.icons8.com/color/48/000000/instagram-new.png"></img>
		</a>
	</p>
      </section>
    </section>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      